<template>
  <b-modal
    ref="refFormModal"
    title="Rechazar producto"
    hide-footer
    no-close-on-backdrop
    size="md"
  >
    <div class="container-fluid">
      <div
        class="row"
      >
        <div class="col-12">
          <div class="form-group">
            <label>Escribe la razón del rechazo</label>
            <textarea
              v-model="reason"
              class="form-control"
              style="resize: none; height: 150px;"
            />
          </div>

          <button
            class="btn btn-primary"
            style="float:right"
            :disabled="reason.trim().length===0"
            @click="send"
          >
            <span v-if="!loading">
              Enviar
            </span>
            <b-spinner
              v-if="loading"
              small
            />
          </button>
        </div>
      </div>

    </div></b-modal>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'SuggestionInputModal',
  components: { BSpinner },
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
      loading: false,
    }
  },
  methods: {
    show() {
      this.$refs.refFormModal.show()
    },
    send() {
      this.loading = true
      GeneralService.getInstance().save('product/reject_reason', {
        product_id: this.productId,
        reject_reason: this.reason,
      }).then(() => {
        this.$emit('save')
      })
    },
  },
}
</script>

<style scoped>

</style>
