<template>
  <b-modal
    ref="refFormModal"
    title="Verificación de producto"
    hide-footer
    no-close-on-backdrop
    size="xl"
    @show="showed=true;"
    @hidden="showed=false"
  >
    <div v-if="showed">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Precio público</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="(product.getAttr('public_price'))"
              >
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>Para el vendedor</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="(product.getAttr('net_price'))"
              >
            </div>
          </div>

          <div class="col-12">
            <div class="btn-group mb-2">
              <a
                :href="'https://irenebuffa.com/producto/' + product.getAttr('slug')"
                class="btn btn-secondary"
                target="_blank"
              >
                Ver más detalles
              </a>

              <button
                class="btn btn-danger"
                @click="rejectProduct"
              >
                Rechazar producto
              </button>
            </div>

            <div class="images-container">
              <div
                v-for="multimedia in product.getAttr('multimedia', false)"
                :key="multimedia.id"
                class="img"
              >
                <a
                  :href="multimedia.file_url"
                  target="_blank"
                >
                  <img
                    :src="multimedia.file_url"
                    :alt="multimedia.filename"
                    style="width: 250px;"
                  >
                </a>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="d-flex mt-2 mb-2 align-items-center ">
              <h4 class="mr-1 mb-0">
                Sugerencias de cambios
              </h4>
              <button
                class="btn btn-primary"
                @click="addSuggestion"
              >
                Agregar sugerencia
              </button>
            </div>
            <div
              v-if="suggestions.length===0"
              class="text-center"
            >
              <h3>No se ha realizado ninguna sugerencia</h3>
            </div>
            <div
              v-if="suggestions.length>0"
              class="images-container"
              style="padding:10px;"
            >
              <div
                v-for="suggestion in suggestions"
                :key="suggestion.getAttr('id')"
                class="card m-0 box-shadow-0 border mr-1"
              >
                <div class="card-body">
                  <p>
                    {{ suggestion.getAttr('comments') }}
                  </p>
                  <p class="m-0">
                    Fecha: {{ dateFormat(suggestion.getAttr('created_at')) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <hr>
          </div>

          <div class="col-12">
            <h4 class="mt-2 mb-1">
              Contrapropuestas
            </h4>
            <table-component
              ref="tableComponent"
              :model="model"
              :init-filter="[
                {
                  field: 'product_id',
                  value: product.getAttr('id'),
                },
              ]"
              :permissions="{
                create: true,
                edit: false,
                delete: false,
                filter: false,
              }"
              @add-new="addNew"
            />
          </div>
        </div>
      </div>

      <suggestion-input-modal
        v-if="product"
        ref="suggestionInputModal"
        :product-id="product.getAttr('id').toString()"
        @save="suggestionSaved"
      />

      <reject-product-input-modal
        v-if="product"
        ref="rejectProductInputModal"
        :product-id="product.getAttr('id').toString()"
        @save="rejectSaved"
      />
    </div>
  </b-modal>
</template>

<script>
// import { BSpinner } from 'bootstrap-vue'
import helpers from '@/dc-it/services/helpers'
import CounterProposals from '@/dc-it/models/CounterProposals'
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Suggestion from '@/dc-it/models/Suggestion'
import SuggestionInputModal from '@/views/components/modals/SuggestionInputModal.vue'
import RejectProductInputModal from '@/views/components/modals/RejectProductInputModal.vue'

export default {
  name: 'ProductVerifyModal',
  components: { SuggestionInputModal, TableComponent, RejectProductInputModal/* BSpinner */ },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: CounterProposals,
      suggestions: [],
      showed: false,
    }
  },
  mounted() {
    // this.loadSuggests()
    /* this.$refs.tableComponent.filterFields =
    this.$refs.tableComponent.applyFilter() */
  },
  methods: {
    show() {
      this.$refs.refFormModal.show()
      this.loadSuggests()
    },
    rejectSaved() {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
    suggestionSaved() {
      this.product.setAttr('status', 6) // En espera del vendedor
      this.loadSuggests()
    },
    addNew() {
      this.$refs.tableComponent.modelSelected.setAttr('product_id', this.product.getAttr('id'))
    },
    numberFormat(number) {
      return helpers.numberFormat(number)
    },
    addSuggestion() {
      this.$refs.suggestionInputModal.show()
    },
    rejectProduct() {
      this.$refs.rejectProductInputModal.show()
    },
    loadSuggests() {
      Suggestion.where('product_id', '=', this.product.getAttr('id')).get().then(suggestions => {
        this.suggestions = suggestions
      })
    },
    dateFormat(date) {
      return helpers.dateFormat(new Date(date))
    },
  },
}
</script>

<style scoped lang="scss">
.images-container {
  display: flex;
  overflow-x: scroll;

  a {
    padding: 10px;
  }
}
</style>
