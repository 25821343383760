<template>
  <b-media vertical-align="center">
    <template #aside>
      <b-avatar
        size="70"
        :src="product.getAttr('main_multimedia').file_url"
      />
    </template>
    <span
      class="font-weight-bold d-block text-nowrap"
    >
      {{ value }}
    </span>
    <span
      v-if="product.getAttr('internal_product', false).toString()==='0'"
      class="d-block text-nowrap"
    >
      @{{
        product.getAttr('customer', false).getAttr('first_name')
      }} {{ product.getAttr('customer', false).getAttr('last_name') }}
    </span>
    <small class="text-muted d-block">
      <a
        :href="'https://irenebuffa.com/producto/' + product.getAttr('slug')"
        class="btn btn-link p-0"
        target="_blank"
      >
        Ver producto
      </a>

    </small>

    <b-badge
      pill
      :variant="pillVariant()"
      style="cursor: pointer;"
      @click="openModal"
    >
      {{ pillText() }}
    </b-badge>

    <product-verify-modal
      ref="productVerifyModal"
      :product="product"
    />

  </b-media>
</template>

<script>

import { BAvatar, BBadge, BMedia } from 'bootstrap-vue'
import User from '@/dc-it/models/User'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import ProductVerifyModal from '@/views/components/modals/ProductVerifyModal.vue'

export default {
  name: 'ProductInfoComponent',
  components: {
    ProductVerifyModal,
    BMedia,
    BAvatar,
    BBadge,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String],
      required: false,
      default: '',
    },
  },
  methods: {
    openModal() {
      const validStatuses = ['1', '6']
      if (validStatuses.includes(this.product.getAttr('status', false).toString())) {
        this.$refs.productVerifyModal.show()
      }
    },
  },
  setup(props) {
    const product = ref(null)
    if (!props.model.getAttr) {
      const tmp = new User()
      tmp.initValues(props.model)
      product.value = tmp
    } else {
      product.value = props.model
    }

    const pillVariant = () => {
      switch (product.value.getAttr('status', false).toString()) {
        // 0=>inactivo 1=>evaluando 2=>activo 3=>out_of_stock 4=>pre-order 5=>Borrador 6=>En espera de vendedor
        case '0':
          return 'danger'
        case '1':
        case '6':
          return 'warning'
        case '2':
          return 'success'
        case '3':
          return 'primary'
        case '4':
          return 'primary'
        case '5':
          return 'info'
        default:
          return 'primary'
      }
    }

    const pillText = () => {
      switch (product.value.getAttr('status', false).toString()) {
        // 0=>inactivo 1=>evaluando 2=>activo 3=>out_of_stock 4=>pre-order 5=>Borrador 6=>En espera de vendedor
        case '0':
          if (product.value.getAttr('reject_reason')) {
            return 'Rechazado de tienda'
          }
          return 'Inactivo'
        case '1':
          return 'Evaluando'
        case '2':
          return 'Activo'
        case '3':
          return 'Out of stock'
        case '4':
          return 'Pre-Order'
        case '5':
          return 'Borrador'
        case '6':
          return 'En espera del vendedor'
        default:
          return 'Estado desconocido'
      }
    }

    return {
      product,
      pillVariant,
      pillText,
    }
  },
}
</script>

<style scoped>

</style>
