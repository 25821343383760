<template>
  <b-modal
    ref="refFormModal"
    title="Agregar sugerencia"
    hide-footer
    no-close-on-backdrop
    size="md"
  >
    <div class="container-fluid">
      <div
        class="row"
      >
        <div class="col-12">
          <div class="form-group">
            <label>Escribe la sugerencia</label>
            <textarea
              v-model="suggestion"
              class="form-control"
              style="resize: none; height: 150px;"
            />
          </div>

          <button
            class="btn btn-primary"
            style="float:right"
            :disabled="suggestion.trim().length===0"
            @click="send"
          >
            <span v-if="!loading">
              Enviar
            </span>
            <b-spinner
              v-if="loading"
              small
            />
          </button>
        </div>
      </div>

    </div></b-modal>
</template>

<script>
import Suggestion from '@/dc-it/models/Suggestion'
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'SuggestionInputModal',
  components: { BSpinner },
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      suggestion: '',
      loading: false,
    }
  },
  methods: {
    show() {
      this.$refs.refFormModal.show()
    },
    send() {
      this.loading = true
      const s = new Suggestion()
      s.setAttr('product_id', this.productId)
      s.setAttr('comments', this.suggestion)
      s.save().then(() => {
        this.loading = false
        this.$refs.refFormModal.hide()
        this.suggestion = ''
        this.$emit('save')
      })
    },
  },
}
</script>

<style scoped>

</style>
