import helpers from '@/dc-it/services/helpers'
import Field from '../types/Field'
import Relation from '../types/Relation'
import Select from '../types/Select'

import Address from './Address'
import Customer from './Customer'
import Model from './Model'

export default class Order extends Model {
    endpoint = 'orders';

    related = ['order_details', 'customer', 'address'];

    // 0=>cancelada 1=>pendiente de pago 2=>confirmada 3=>envio de vendedor 4=>envio a cliente
    orderStatus = {
      0: 'Cancelada',
      1: 'Pendiente',
      2: 'Confirmado',
      3: 'Envío de vendedor',
      4: 'Envío de cliente',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('customer', 'Cliente', Customer, 'email'),
      new Field('public_total', 'Total público', 'number').applyMask(val => `$ ${helpers.numberFormat(val)}`),
      new Field('net_total', 'Total neto', 'number').applyMask(val => `$ ${helpers.numberFormat(val)}`),
      new Select('status', 'Estado', this.orderStatus),
      new Relation('address', 'Dirección', Address, 'name').hide(),
      new Field('discount_amount', 'Descuento', 'number').hide(),
      new Field('cancelled_at', 'Fecha de cancelación').hide(),
      new Field('shipment', 'Envío', 'number').applyMask(val => `$ ${helpers.numberFormat(val)}`),
      // new Relation('order_detail_id', 'Detalle de la orden', OrderDetail, 'id'),

    ]

    clone = () => Order;
}
