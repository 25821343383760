import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Select from '@/dc-it/types/Select'

export default class Suggestion extends Model {
    endpoint = 'suggestions';

    statuses = {
      1: 'En espera de vendedor',
      2: 'Resuelta',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('product_id', 'Product'),
      new Field('comments', 'Comentario'),
      new Select('status', 'Status', this.statuses),
    ]

    clone = () => Suggestion;
}
