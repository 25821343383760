import helpers from '@/dc-it/services/helpers'
import Select from '@/dc-it/types/Select'
import Model from './Model'

import Field from '../types/Field'

export default class CounterProposals extends Model {
    endpoint = 'counter_proposals'

    // 0=>rechazada 1=>enviada 2=>aceptada

     statuses = {
       0: 'Rechazar',
       1: 'Esperando respuesta',
       // 2: 'Aceptada',
     }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('product_id', 'Producto').hide().disabled(),
      new Select('status', 'Estado', this.statuses).disabled(),
      new Field('admin_comment', 'Comentario de administrador'),
      new Field('seller_comment', 'Comentario de vendedor').disabled(),
      new Field('public_price', 'Precio sugerido de venta').applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('net_price', 'Monto para vendedor').applyMask(val => `$${helpers.numberFormat(val)}`).disabled(),
    ]

    clone = () => CounterProposals;
}
