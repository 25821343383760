<template>
  <div>
    <div
      v-if="order"
      class="btn-group mb-2"
    >
      <button
        disabled
        class="btn btn-outline-primary"
      >
        Estado de orden: {{ order.getAttr('status') }}
      </button>
      <button
        class="btn btn-danger"
        :disabled="loading || order.getAttr('status', false).toString()!=='2'"
        @click="cancelOrder"
      >
        Cancelar orden
      </button>

      <button
        class="btn btn-success"
        :disabled="loading || order.getAttr('status', false).toString()!=='1'"
        @click="confirmOrder"
      >
        Confirmar orden
      </button>
    </div>

    <div v-if="!loading && order">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">
            Información de cliente
          </h2>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Nombre(s)</label>
                <input
                  type="text"
                  class="form-control"
                  :value="order.getAttr('customer', false).getAttr('first_name')"
                  disabled
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Apellido(s)</label>
                <input
                  type="text"
                  class="form-control"
                  :value="order.getAttr('customer', false).getAttr('last_name')"
                  disabled
                >
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Teléfono</label>
                <input
                  type="text"
                  class="form-control"
                  :value="order.getAttr('customer', false).getAttr('phone')"
                  disabled
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Correo electrónico</label>
                <input
                  type="text"
                  class="form-control"
                  :value="order.getAttr('customer', false).getAttr('email')"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4>Dirección de entrega</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Calle</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('street')"
                >
              </div>
            </div>
            <div
              v-if="order.getAttr('address', false).getAttr('street')==='Extranjero'"
              class="col-12"
            >
              <div class="form-group">
                <label>Dirección</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('address')"
                >
              </div>
            </div>
            <div
              v-if="order.getAttr('address', false).getAttr('street')!=='Extranjero'"
              class="col-12 col-md-4"
            >
              <div class="form-group">
                <label>Número exterior</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('ext_number')"
                >
              </div>
            </div>
            <div
              v-if="order.getAttr('address', false).getAttr('street')!=='Extranjero'"
              class="col-12 col-md-4"
            >
              <div class="form-group">
                <label>Número interior</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('int_number')"
                >
              </div>
            </div>
            <div
              v-if="order.getAttr('address', false).getAttr('street')!=='Extranjero'"
              class="col-12 col-md-6"
            >
              <div class="form-group">
                <label>Colonia</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('colony')"
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>CP</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('zip_code')"
                >
              </div>
            </div>
            <div
              v-if="!order.getAttr('address', false).getAttr('related_city')"
              class="col-12 col-md-6"
            >
              <div class="form-group">
                <label>Ciudad</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('city')"
                >
              </div>
            </div>
            <div
              v-if="order.getAttr('address', false).getAttr('related_city')"
              class="col-12 col-md-6"
            >
              <div class="form-group">
                <label>Ciudad</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('related_city').name"
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>País</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('address', false).getAttr('country')"
                >
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label>Referencias</label>
                <textarea
                  class="form-control"
                  disabled
                  style="resize: none; height: 150px;"
                  :value="order.getAttr('address', false).getAttr('references')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h2 class="card-title">
            Información de la orden
          </h2>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Precio</th>
                      <th>Para vendedor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="orderDetail in order.getAttr('order_details', false)"
                      :key="orderDetail.id"
                    >
                      <td>
                        <product-info-component
                          :model="orderDetail.product"
                          :value="orderDetail.product.name_es"
                        />
                      </td>
                      <td>
                        <p class="m-0">
                          $ {{ numberFormat(orderDetail.public_price) }}
                        </p>
                        <div v-if="orderDetail.entrupy_certificate.toString()==='1'">
                          <p class="m-0">
                            Certificado Entrupy: {{ (orderDetail.entrupy_certificate_price == 0) ? 'Gratis' : '$ ' + numberFormat(orderDetail.entrupy_certificate_price) }}
                          </p>
                        </div>
                      </td>
                      <td>$ {{ numberFormat(orderDetail.net_price) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Total</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('public_total')"
                >
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Costo de envío</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('shipment')"
                >
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label>Para vendedor</label>
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="order.getAttr('net_total')"
                >
              </div>
            </div>
          </div>

        </div>
      </div>

      <div
        v-if="order.getAttr('order_payments',false).length>0"
        class="card"
      >
        <div class="card-header">
          <h2 class="card-title">
            Pagos
          </h2>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div
                v-for="(payment, index) in order.getAttr('order_payments',false)"
                :key="payment.id"
                class="card border"
              >
                <div class="card-header">
                  <h4 class="card-title">
                    Pago {{ index + 1 }}
                  </h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label>Monto</label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="numberFormat(payment.amount)"
                        >
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label>Método de pago</label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="paymentMethods[payment.payment_method.toString()]"
                        >
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label>Estado del pago</label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="paymentStatus[payment.status.toString()]"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="d-flex align-items justify-content-center"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import Order from '@/dc-it/models/Order'
import ProductInfoComponent from '@/views/components/ProductInfoComponent.vue'
import helpers from '@/dc-it/services/helpers'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'OrderDetailsView',
  components: { BSpinner, ProductInfoComponent },
  data() {
    return {
      loading: true,
      order: null,
      id: null,
      paymentMethods: {
        1: 'PayPal',
        2: 'Mercado Pago',
        3: 'Kueski pay',
        4: 'Stripe',
        5: 'Cashback',
        6: 'Balance de vendedor',
        7: 'Efectivo',
      },
      paymentStatus: {
        2: 'Pagado',
        1: 'No pagado',
        0: 'Cancelado',
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    Order.where('id', '=', this.id).with(['order_payments', 'order_details.product', 'address.related_city', 'customer']).get().then(order => {
      if (order.length !== 1) {
        // eslint-disable-next-line no-alert
        alert('No se encontró la orden')
        return
      }

      // eslint-disable-next-line prefer-destructuring
      this.order = order[0]
      this.loading = false
    })
  },

  // 0=>cancelado 1=>activo
  methods: {
    numberFormat(number) {
      return helpers.numberFormat(number)
    },
    cancelOrder() {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('¿Está seguro que desea cancelar la orden?')) {
        this.loading = true
        GeneralService.getInstance().save('orders/cancel', { order_id: this.id }).then(response => {
          if (response.result !== 'success') {
            // eslint-disable-next-line no-alert
            alert('Ha sucedido un error desconocido en el servidor')
          }

          window.location.reload()
        })
      }
    },
    confirmOrder() {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('¿Está seguro que desea confirmar la orden?')) {
        this.loading = true
        GeneralService.getInstance().save('orders/confirm', { order_id: this.id }).then(response => {
          if (response.result !== 'success') {
            // eslint-disable-next-line no-alert
            alert('Ha sucedido un error desconocido en el servidor')
          }

          window.location.reload()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
